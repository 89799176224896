export { default as defaultLocale } from './lang/en.json';

export {default as da} from './lang/da.json';
export {default as de} from './lang/de.json';
export {default as en} from './lang/en.json';
export {default as es} from './lang/es.json';
export {default as fi} from './lang/fi.json';
export {default as fr} from './lang/fr.json';
export {default as nl} from './lang/nl.json';
export {default as no} from './lang/no.json';
export {default as sv} from './lang/sv.json';

import Content from 'components/Content';
import Link from 'components/ui/Link';
import Text from 'components/ui/Text';
import { useTranslation } from 'hooks/useTranslation';
import { FC } from 'react';
import { selectGeneralState } from 'store/generalSlice';
import { useAppSelector } from 'store/hooks';

type CopyrightFooterProps = {
  absolute?: boolean;
};

const CopyrightFooter: FC<CopyrightFooterProps> = ({ absolute }) => {
  const general = useAppSelector(selectGeneralState);
  const translation = useTranslation();

  return absolute ? (
    <div className="absolute bottom-8 left-0 w-full text-center">
      <Content noMargin>
        <Text color="grey-700" elementType="span">
          {general?.miscellaneous?.copyrightText}
          {general?.miscellaneous && (
            <Link className="ml-8" link={general.miscellaneous.contactLink} />
          )}
          <Link
            className="ml-8"
            link={{
              text: translation('contactSupport'),
              url: 'mailto:support.recipe-of-light@fagerhult.se',
              target: '_blank',
            }}
          />
        </Text>
      </Content>
    </div>
  ) : (
    <div className="py-8 bg-complementary-7 w-full text-center">
      <Content noMargin>
        <Text color="grey-700" elementType="span">
          {general?.miscellaneous?.copyrightText}
          <br className="sm:hidden" />
          {general?.miscellaneous && (
            <Link
              className="sm:ml-8"
              link={general.miscellaneous.contactLink}
            />
          )}
          <Link
            className="sm:ml-8"
            link={{
              text: translation('contactSupport'),
              url: 'mailto:support.recipe-of-light@fagerhult.se',
              target: '_blank',
            }}
          />
        </Text>
      </Content>
    </div>
  );
};

export default CopyrightFooter;

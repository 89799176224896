import classNames from 'classnames';
import NextLink from 'next/link';
import { FC } from 'react';
import { Link } from 'type';

type LinkProps = {
  link: Link;
  className?: string;
};

const Link: FC<LinkProps> = ({ link, className }) => {
  const classes = classNames('text-link', className);

  return (
    <NextLink href={link.url} target={link.target} className={classes}>
      {link.text}
    </NextLink>
  );
};

export default Link;

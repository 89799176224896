import { FC } from 'react';
import { PortableText } from '@portabletext/react';
import type { PortableTextBlock } from '@portabletext/types/dist';

type RichTextProps = {
  text: PortableTextBlock[];
};

const RichText: FC<RichTextProps> = ({ text }) => {
  const innerText = text.map((_text) => ({
    ..._text,
    children: _text.children.map((child) => ({
      ...child,
      text: child.text.replace(/\s+/g, ' '),
    })),
  }));

  return <PortableText value={innerText} />;
};

export default RichText;
